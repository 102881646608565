<template>
  <div class="modal topup-modal">
    <div class="overlay"  @click="$parent.closeTopUpModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeTopUpModal" src="./../assets/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields" v-if="$parent.topUpModalAction == 'deposit'">
              <div class="title small">Billing information</div>
              <div class="wrapper">
                <div class="input-container input-container-amount">
                  <div class="desc"><span class="red">*</span>Amount:</div>
                  <input type="text" @input="allowOnlyNumbers" placeholder="Amount" v-model="amount"/>
                  <div class="desc curr">{{ $parent.currency }}</div>
                </div>
                <div class="input-container">
                  <div class="desc"><span class="red">*</span>Email:</div>
                  <input type="email" placeholder="Email" v-model="email"/>
                </div>
                <div class="input-container">
                  <div class="desc"><span class="red">*</span>Phone:</div>
                  <input type="number" @input="allowOnlyNumbers" placeholder="Phone" v-model="phone"/>
                </div>
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms"/>
                      <div class="checkbox"></div>
                      <span class="title">I agree with </span>
                      <a v-if="textPageList" @click="$parent.goToPrivacy()" class="title"> {{ textPageList[1].title }}</a>
                      <span class="title"> and </span>
                      <a v-if="textPageList" @click="$parent.goToTerms()" class="title"> {{ textPageList[0].title }}</a>
                    </div>
                  </label>
                </div>
                <button :class="['button dark', {'disabled': !requiredFieldsAreFilled}]" @click="submit">
                  <span>Top Up</span>
                </button>
                <transition name="fade">
                  <div v-if="$parent.error" class="desc error-desc red">{{$parent.error}}</div>
                </transition>
              </div>
            </div>
            <div class="form-fields" v-if="$parent.topUpModalAction == 'withdraw'">
              <div class="title small">Withdraw information</div>
              <div class="wrapper" v-if="!$parent.successMessage">
                <div class="input-container input-container-amount">
                  <div class="desc"><span class="red">*</span>Amount:</div>
                  <input type="text" @input="allowOnlyNumbers" placeholder="Amount" v-model="amount"/>
                  <div class="desc curr">{{ $parent.currency }}</div>
                </div>
                <div class="input-container">
                  <div class="desc"><span class="red">*</span>Card number:</div>
                  <input type="number" placeholder="Card number" v-model="cardNum"/>
                </div>
                <button class="button dark" @click="withdraw">
                  <span>Withdraw</span>
                </button>
                <transition name="fade">
                  <div v-if="$parent.error" class="desc error-desc red">{{$parent.error}}</div>
                </transition>
              </div>
              <div class="wrapper" v-if="$parent.successMessage">
                <div class="title">{{ $parent.successMessage }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopUpModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      phone: '',
      email: '',
      amount: '',
      cardNum: '',
      terms: false,
      textPageList: ''
    }
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.email && this.phone && 
        this.amount && this.terms
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  mounted() {
    this.email = this.$parent.profileData.email;
    this.$http.get(process.env.VUE_APP_API + 'static-pages')
    .then((res) => {
      this.textPageList = res.data.payload
    })
    .catch(() => {
        
    })
  },
  methods: {
    allowOnlyNumbers(){
      this.amount = this.amount.replace(/[^0-9.,]/g, '');

      // Split the input into integer and decimal parts
      const parts = this.amount.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.amount = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    submit() {
      let data = {
        "phone": this.phone,
        "email": this.email,
        "amount": this.amount
      }
      this.$emit('topUpSubmit', data)
    },
    withdraw() {
      let data = {
        "cc_num": this.cardNum,
        "amount": this.amount
      }
      this.$emit('withdrawSubmit', data)
    },
  }
}
</script>