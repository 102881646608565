<template>
   <div class="modal promt-create-modal">
    <div class="overlay"  @click="$parent.closePromptCreateModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closePromptCreateModal" src="./../assets/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title small">SELL</div>
                <div class="select-container" v-if="toolOptions.length">
                  <label class="product-list-checkbox" v-for="item in toolOptions" :key="item.id">
                    <input type="radio" v-model="tool" :value="item.id" />
                    <div class="desc">{{item.title}}</div>
                  </label>
                </div>
                <div class="input-container input-container-upload">
                  <label :class="['img-label', {'active': image}]">
                    <input type="file" @change="setImage" />
                    <div :class="['prompt-desc', {'active': image}]">
                      <img v-if="image"  :src='image' class="img"/>
                      <div v-if="image && imageFile" class="desc">{{imageFile.name}}</div>
                      <div v-if="!image" class="desc"><span class="span-link">Add</span> <span>file here ({{ formats }})</span></div>
                    </div>
                  </label>
                </div>
                <div class="select-container short-left">
                  <select v-model="activeType">
                    <option selected disabled hidden value="">Type</option>
                    <option value="image">Images</option>
                    <option value="doc">Texts</option>
                  </select>
                </div>

                <div class="select-container short-right" v-if="categoryOptions.length">
                  <select v-model="activeCategory">
                    <option selected disabled hidden value="">Category</option>
                    <option :value="item.id" v-for="item in categoryOptions " :key="item.id">{{item.title}}</option>
                  </select>
                </div>
                
                <div class="input-container">
                  <input type="text" v-model="title" placeholder="Title"/>
                </div>

                <div class="input-container">
                  <textarea placeholder="Description" v-model="description"></textarea>
                </div>

                <div class="input-container">
                  <input type="text" placeholder="Keywords" v-model="keyWords"/>
                </div>

                <div class="input-container input-container-price">
                  <input type="text" v-model="price" @input="allowOnlyNumbers" placeholder="Price"/>
                  <div class="desc">{{ $parent.currency }}</div>
                </div>
                
                <transition name="fade">
                  <div v-if="error" class="desc error-desc red">{{error}}</div>
                </transition>
                <transition name="fade">
                  <div v-if="successMessage" class="desc success-desc green">{{successMessage}}</div>
                </transition>
                <div class="buttons">
                  <div class="button border" @click="$parent.closePromptCreateModal">Cancel</div>
                  <div class="button dark" @click="send">Confirm</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PromtCreate',
  props: [],
  components: {
  },
  data: function() {
    return {
      activeCategory: '',
      activeType: '',
      tool: '',
      title: '',
      price: '',
      keyWords: '',
      description: '',
      image: null,
      imageFile: null,
      editData: null,
      categoryOptions: [],
      error: '',
      successMessage: '',
      formats: ''
    }
  },
  mounted() {
    this.getFormats();
    this.fetchTools();
    this.getCategories();
    if (this.$parent.editData) {
      this.editData = this.$parent.editData;
    }
  },
  methods: {
    allowOnlyNumbers(){
      this.price = this.price.replace(/[^0-9.,]/g, '');

      // Split the input into integer and decimal parts
      const parts = this.price.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.price = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    getFormats() {
      this.$http.get(process.env.VUE_APP_API + 'available-formats')
      .then((res) => {
        this.formats = res.data.payload;
      })
      .catch(() => {
         
      })
    },
    getCategories() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categoryOptions = res.data.payload;

        console.log('editdata')
        console.log(this.editData)
        
        if (this.editData) {
          const chosenCategory = this.categoryOptions.find(obj => obj.id === this.editData.category_id).id;
          this.activeCategory = chosenCategory;
          this.title = this.editData.title;
          this.price = this.editData.price;
          this.activeType = this.editData.product_type;
          this.keyWords = this.editData.keywords;
          this.description = this.editData.description;
          this.image = this.$parent.imgDomain + this.editData.doc_url;
        } else {
          this.editData = null;
        }
        setTimeout(function() {
          self.$parent.clearEditData();
        }, 200)
      })
      .catch(() => {
         
      })
    },
    setImage(event) {
      const selectedFile = event.target.files[0];
      this.imageFile = selectedFile;
      if (selectedFile) {
        const reader = new FileReader();

        reader.onload = () => {
          this.image = reader.result;
        };

        reader.readAsDataURL(selectedFile);
      } else {
        this.image = null;
      }
    },
    send() {
      this.error = '';
      const formData = new FormData();
      formData.append('media', this.imageFile);
      formData.append('category_id', this.activeCategory);
      formData.append('tool_id', this.tool);
      formData.append('price', this.price);
      formData.append('title', this.title);
      formData.append('description', this.description);
      formData.append('product_type', this.activeType);
      formData.append('keywords', this.keyWords);

      if (this.editData) {
        this.$http.post(process.env.VUE_APP_API + 'user/media/' + this.editData.id + '/edit', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = 'success';
            let self = this;
            setTimeout(function() {
              self.activeCategory = '',
              self.title = '',
              self.keyWords  ='',
              self.description = '',
              self.price = '',
              self.activeType = '',
              self.image = null,
              self.imageFile = null;
              self.successMessage = '';
              self.$parent.getCreatedHistory();
              self.$parent.closePromptCreateModal();
            }, 2000);
          } else {
            this.error = res.response.data.message;
          }
        })
        .catch((res) => {
          this.error = res.response.data.message;
          if(res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
      } else {
        this.$http.post(process.env.VUE_APP_API + 'user/media/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          console.log(res);
          if (res.data.status == "OK") {
            this.error = '';
            let par = {'prompt_id' : res.data.id}
            this.$http.post(process.env.VUE_APP_API + 'user/media/send-to-approve', par, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(() => {
              if (res.data.status == "OK") {
                this.successMessage = 'success';
                let self = this;
                setTimeout(function() {
                  self.activeCategory = '',
                  self.title = '',
                  self.keyWords  ='',
                  self.description  ='',
                  self.price = '',
                  self.activeType = '',
                  self.image = null,
                  self.imageFile = null;
                  self.successMessage = '';
                  self.$parent.getCreatedHistory();
                  self.$parent.closePromptCreateModal();
                }, 2000);
              }
            })
            .catch((res) => {
              console.log(res);
            })
          } else {
            this.error = res.response.data.message;
          }
        })
        .catch((res) => {
          if (res.response.data.errors) {
             if (res.response.data.errors.media) {
              this.error = res.response.data.errors.media[1];
            } else if (res.response.data.errors.category_id) {
              this.error = res.response.data.errors.category_id[0];
            } else if (res.response.data.errors.product_type) {
              this.error = res.response.data.errors.product_type;
            } else if (res.response.data.errors.title) {
              this.error = res.response.data.errors.title;
            } else if (res.response.data.errors.keywords) {
              this.error = res.response.data.errors.keywords;
            } else if (res.response.data.errors.price) {
              this.error = res.response.data.errors.price;
            } else if (res.response.data.errors.description) {
              this.error = res.response.data.errors.description;
            } 
          } else {
            this.error = res.response.data.message;
          }
          if(res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
      }
    },
    ...mapActions('tools', ['fetchTools']),
  },
  computed: {
    ...mapGetters('tools', {toolOptions: 'tools'})
  }
}
</script>