<template>
  <div class="modal sign-modal">
    <div class="overlay"  @click="$emit('closeSignInModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeSignInModal')" src="./../assets/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div :class="['form-fields login-content', {'hidden': !loginContentVisible}]">
              <div class="form-fields-wrapper">
                <div class="tabs">
                  <div class="title small">Sign In</div>
                </div>
                <div class="desc">Log in to your account</div>
                <label>
                  <div class="desc">Электронная почта:</div>
                  <input type="email" placeholder="E-mail" v-model="email"/>
                </label>
                <label>
                  <div class="desc">Пароль:</div>
                  <input type="password" placeholder="Password" v-model="pass"/>
                </label>
                <div class="link" @click="switchToforgotPass">Forget your password?</div>
                <button class="button dark" @click="submitLogin">
                  <span>Continue</span>
                </button>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                </transition>
                <div class="modal-bottom">
                  <div class="button" @click="$emit('openSignUpModal')">SIGN UP</div>
                </div>
              </div>
            </div>
            <div :class="['form-fields forgot-pass-content', {'hidden': !forgotPassContentVisible}]">
              <div class="form-fields-wrapper">
                <div class="title">Forget your password?</div>
                <label>
                  <div class="desc">Электронная почта:</div>
                  <input type="email" placeholder="E-mail" v-model="email"/>
                </label>
                <button class="button dark" @click="submitForgotPass">
                  <span>Continue</span>
                </button>
                <transition name="fade">
                  <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
                </transition>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                </transition>
                <div class="modal-bottom">
                  <div class="button" @click="switchToLogin()">Sign in</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignInModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: ''
    }
  },
  mounted() {
    
  },
  methods: {
    closeSignInModal() {
      this.$emit('closeSignInModal')
    },
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100)
    },
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    }
  }
}
</script>