<template>
	<header class="header">
    <div class="wrapper">
      <div class="flex">
        <div class="flex-left">
          <router-link to="/" class="logo">
            <img src="./../assets/logo.svg" class="img"/>
          </router-link>
        </div>
        <div class="flex-center">
          <ul class="nav">
            <li class="nav__item" @click="scrollToSection('features')">
              <div class="desc">Our Features</div>
            </li>
            <li class="nav__item" @click="scrollToSection('categories')">
              <div class="desc">Categories</div>
            </li>
            <li class="nav__item" @click="scrollToSection('how-it-works')">
              <div class="desc">How It Works</div>
            </li>
            <li class="nav__item" @click="scrollToSection('contact')">
              <div class="desc">Contact Us</div>
            </li>
            <router-link to="/product-list" class="nav__item">
              <div class="desc">Marketplace</div>
            </router-link>
          </ul>
        </div>
        <div class="flex-right">
          <div class="header-profile" v-if="$parent.isAuth">
            <div class="select-container cart" v-if="curr && ($parent.currencyOptions.length > 1)">
              <select class="select currency-select" v-model="curr"
                @change="selectCurr()"
              >
                <option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
              </select>
            </div>
            <div class="cart" @click="$parent.openBuyModal('')">
              <img class="img" src="./../assets/cart.svg"/>
              <div v-if="$parent.cartContents && $parent.cartContents.length" class="indicator">{{ $parent.cartContents.length }}</div>
            </div>
            <router-link class="button" to="/profile/profile-edit">
              My account
              <!-- <div>{{ $parent.profileData.email }}</div> -->
            </router-link>
          </div>
          <div v-else class="buttons">
            <div class="select-container cart" v-if="curr && ($parent.currencyOptions.length > 1)">
              <select class="select currency-select" v-model="curr"
                @change="selectCurr()"
              >
                <option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
              </select>
            </div>
            <div class="button" @click="$emit('openSignInModal')">
              <span>Log In</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
	name: 'Header',
  props: ['currencyCode', 'currencyValue'],
	data: function() {
		return {
      curr: ''
		}
	},
  computed: {
    currOptions() {
      return this.$parent.currencyOptions.map(({code}) => code);
    }
  },
  watch: {
    currencyCode: function (newValue) {
      this.curr = newValue
    }
  },
  mounted() {
    this.curr = this.currencyCode
  },
	methods: {
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    },
    scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    selectCurr() {
      this.changeCurrency(this.curr)
      localStorage.setItem("currency", this.curr);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
	}
}
</script>
